import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  FormControl,
  Grid,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useFormikContext } from "formik";
import { Label } from "app/pages/presentation/module/form/types";
import { getGallery } from "app/services/api/media";
import DeleteIcon from "@mui/icons-material/Delete";
import Galleries from "app/pages/media/galleries/Galleries";
import HelpIcon from "@mui/icons-material/Help";
import Images from "app/pages/media/images/Images";
import { PROD_URL } from "app/services/config";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Videos from "app/pages/media/videos/Videos";
import SingleVideo from "app/pages/media/videos/selectSingleVideo";

const FIELD_NAME = "MAIN_FIELD";
const STATUS_LIST = ["Active", "Inactive"];

export const MAIN_FIELD_SCHEMA = {
  label: "",
  status: "",
  languages: [],
  webAddress: "",
  inactivityTimeout: "",
  sliderImageDuration: "",
  mainScreenSlider: "",
  logoDark: "",
  logoNormal: "",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  maxHeight: "90vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const MainField = ({ handleChange, expanded }) => {
  const { t } = useTranslation();
  const { getAuthUser } = useJumboAuth();
  const {
    customerId,
    customer: { kioskSettings },
  } = getAuthUser();
  const { values, setFieldValue } = useFormikContext();
  const [galleryTitle, setGalleryTitle] = useState("");
  const [galleryImage, setGalleryImage] = useState(false);
  const [openSelector, setOpenSelector] = useState();

  useEffect(() => {
    if (values?.main?.mainScreenSlider) {
      getGallery(values?.main?.mainScreenSlider).then((response) => {
        setGalleryTitle(response?.label || "");
        const imageUrl = (response?.images && response?.images[0]?.path) || "";
        setGalleryImage(imageUrl);
      });
    } else {
      setGalleryImage("");
    }
  }, [values.main]);

  const handleGallerySelect = (galleryItem) => {
    setOpenSelector();
    setFieldValue("main.mainScreenSlider", galleryItem.url);
  };

  const handleImageSelect = (name, imageUrl) => {
    setOpenSelector();
    setFieldValue(`main.${name}`, imageUrl);
  };

  const Selector = ({ label, help, title, image, setOpenModal, name }) => {
    return (
      <Div sx={{ mt: 0, mb: 2.5 }}>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            margin: "0 0 0 10px",
            background: "white",
            color: "text.secondary",
            width: "fit-content",
          }}
        >
          {label}
          {help && (
            <Tooltip
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: help,
                  }}
                />
              }
            >
              <HelpIcon fontSize="small" sx={{ pt: 0.3, ml: 0.5, mb: -0.5 }} />
            </Tooltip>
          )}
        </Typography>
        <ImageListItem
          sx={{
            "& .MuiImageListItemBar-root": {
              transition: "visibility 0s, opacity 0.3s linear",
              opacity: 0,
            },

            "&:hover .MuiImageListItemBar-root": {
              visibility: "visible",
              opacity: 1,
            },
            width: "fit-content",
            height: "auto",
          }}
        >
          <Avatar
            src={image || "/images/pages/not_found.svg"}
            variant={"rounded"}
            sx={{
              height: "auto",
              width: `100%`,
              maxWidth: "250px",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal(true)}
          />
          {image && (
            <ImageListItemBar
              subtitle={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography variant="body">{title}</Typography>
                  <DeleteIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setFieldValue(name, "")}
                  />
                </Stack>
              }
            />
          )}
        </ImageListItem>
      </Div>
    );
  };

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.editDevice.main")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name="main.label"
                label={t("pages.presentation.label")}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                select
                name="main.status"
                label={t("pages.screens.status")}
              >
                {STATUS_LIST?.map((status, index) => {
                  return (
                    <MenuItem key={index} value={status}>
                      {status}
                    </MenuItem>
                  );
                })}
              </JumboTextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                select
                name="main.languages"
                SelectProps={{
                  multiple: true,
                }}
                label={
                  <Label
                    text={t("pages.editDevice.language.label")}
                    helpText={t("pages.editDevice.language.help")}
                  />
                }
              >
                {kioskSettings?.languages
                  ?.filter((item, index, self) => self.indexOf(item) == index)
                  .map((language, index) => {
                    return (
                      <MenuItem key={index} value={language.code}>
                        {language.label}
                      </MenuItem>
                    );
                  })}
              </JumboTextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name="main.webAddress"
                label={
                  <Label
                    text={t("pages.editDevice.webAddress.label")}
                    helpText={t("pages.editDevice.webAddress.help")}
                  />
                }
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                type="number"
                fullWidth
                name="main.inactivityTimeout"
                label={
                  <Label
                    text={t("pages.editDevice.inactivityTimeout.label")}
                    helpText={t("pages.editDevice.inactivityTimeout.help")}
                  />
                }
                InputProps={{
                  value: values.main?.inactivityTimeout,
                  inputProps: {
                    min: 0,
                    max: 10,
                  },
                  onChange: (e) =>
                    setFieldValue("main.inactivityTimeout", e.target.value),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                type="number"
                fullWidth
                name="main.sliderImageDuration"
                label={
                  <Label
                    text={t("pages.editDevice.sliderImageDuration.label")}
                    helpText={t("pages.editDevice.sliderImageDuration.help")}
                  />
                }
                InputProps={{
                  value: values.main?.sliderImageDuration,
                  inputProps: {
                    min: 0,
                    max: 20,
                  },
                  onChange: (e) =>
                    setFieldValue("main.sliderImageDuration", e.target.value),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <Selector
              label={t("pages.editDevice.mainScreenSlider.label")}
              help={t("pages.editDevice.mainScreenSlider.help")}
              image={galleryImage}
              title={galleryTitle}
              name="main.mainScreenSlider"
              setOpenModal={() => setOpenSelector("gallery")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Selector
              label={t("pages.editDevice.logoDark.label")}
              image={values.main?.logoDark}
              name="main.logoDark"
              setOpenModal={() => setOpenSelector("logoDark")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Selector
              label={t("pages.editDevice.logoNormal.label")}
              image={values.main?.logoNormal}
              name="main.logoNormal"
              setOpenModal={() => setOpenSelector("logoNormal")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Modal
        open={openSelector !== undefined}
        onClose={() => setOpenSelector()}
      >
        <Div sx={modalStyle}>
          {openSelector === "gallery" ? (
            <Galleries
              selectorMode
              handleSelect={(image) => handleGallerySelect(image)}
            />
          ) : (
            <SingleVideo
              layout={"row-reverse"}
              selectorMode
              onCancel={() => setOpenSelector()}
              onSelect={(image) => {
                const imageUrl = `${PROD_URL}/uploads/files/${customerId}/Images/${image.filename}`;
                handleImageSelect(openSelector, imageUrl);
              }}
            />
            // <Images
            //   layout={"row-reverse"}
            //   selectorMode
            //   onCancel={() => setOpenSelector()}
            //   onSelect={(image) => {
            //     const imageUrl = `${PROD_URL}/uploads/files/${customerId}/Images/${image.filename}`;
            //     handleImageSelect(openSelector, imageUrl);
            //   }}
            // />
          )}
        </Div>
      </Modal>
    </Accordion>
  );
};

export default MainField;
