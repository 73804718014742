import React, { useEffect, useState } from "react";
import EditModule from "../EditModule";
import ModuleSettings from "../ModuleSettings";
import FormController from "../form/FormController";
import Div from "@jumbo/shared/Div";
import HelpIcon from "@mui/icons-material/Help";
import DeleteIcon from "@mui/icons-material/Delete";
import { PROD_URL } from "app/services/config";
import {
  Avatar,
  Grid,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import SingleVideo from "app/pages/media/videos/selectSingleVideo";

const VideoPlayerModule = ({
  module,
  presentation,
  loadModule,
  type,
  customerId,
  videos,
}) => {
  const [settings, setSettings] = useState({});
  const [errors, setErrors] = useState({});
  const [newTextAreaData, setNewTextAreaData] = useState();
  const [openSelector, setOpenSelector] = useState();
  const [updatedVideo, setUpdatedVideo] = useState();
  const [updatedVideoName, setUpdatedVideoName] = useState();

  useEffect(() => {
    setSettings(module?.parameters);
    setNewTextAreaData(settings?.text_area_simple);
  }, [module]);

  const handleVideoSelect = (name, videoUrl, videoLabel, setFieldValue) => {
    setOpenSelector();
    const filename = videoUrl.split(/r/);
    setUpdatedVideo(filename);
    setUpdatedVideoName(videoLabel);
    setFieldValue(`main.${name}`, videoUrl);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75vw",
    maxHeight: "90vh",
    overflow: "scroll",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const Selector = ({
    label,
    help,
    title,
    video,
    setOpenModal,
    name,
    setFieldValue,
  }) => {
    return (
      <Div sx={{ mt: 0, mb: 2.5 }}>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            margin: "0 0 0 10px",
            background: "white",
            color: "text.secondary",
            width: "fit-content",
          }}
        >
          {label}
          {help && (
            <Tooltip
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: help,
                  }}
                />
              }
            >
              <HelpIcon fontSize="small" sx={{ pt: 0.3, ml: 0.5, mb: -0.5 }} />
            </Tooltip>
          )}
        </Typography>
        <ImageListItem
          sx={{
            "& .MuiImageListItemBar-root": {
              transition: "visibility 0s, opacity 0.3s linear",
              opacity: 0,
            },

            "&:hover .MuiImageListItemBar-root": {
              visibility: "visible",
              opacity: 1,
            },
            width: "fit-content",
            height: "auto",
          }}
        >
          <Avatar
            src={video || "/images/pages/video-placeholder.jpg"}
            variant={"rounded"}
            sx={{
              height: "auto",
              width: `100%`,
              maxWidth: "250px",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal(true)}
          />
          {video && (
            <ImageListItemBar
              subtitle={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography variant="body">{title}</Typography>
                  <DeleteIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setFieldValue(name, "")}
                  />
                </Stack>
              }
            />
          )}
        </ImageListItem>
      </Div>
    );
  };

  // Initial form values
  const initialValues = {
    main: {
      selectedVideo: settings?.select || "",
    },
  };
  const selectedVideo = initialValues?.main?.selectedVideo;
  let selectedVideoLabel = "";
  if (videos) {
    const videoFile = selectedVideo.split("/").pop();
    const foundVideo = videos?.media?.find(
      (video) => video.filename === videoFile
    );
    selectedVideoLabel = foundVideo?.label;
  }

  return (
    <Div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          // Handle form submission if needed
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            <EditModule
              type={type}
              module={module}
              presentation={presentation}
              loadModule={loadModule}
              settings={
                module &&
                module.parameters && (
                  <ModuleSettings module={module}>
                    <FormController
                      setSettings={setSettings}
                      setErrors={setErrors}
                    />
                  </ModuleSettings>
                )
              }
              updateValue={{
                settings: {
                  url: module?.setting?.url,
                  parameters: {
                    ...settings,
                    text_area_simple: newTextAreaData,
                    select: values?.main.selectedVideo.split("\r").pop(),
                  },
                },
                errors: errors,
              }}
              content={
                <>
                  <Div
                    sx={{ bgcolor: "background.paper", mx: 2, width: "75%" }}
                  ></Div>
                  <Div
                    sx={{ bgcolor: "background.paper", mx: 2, width: "75%" }}
                  >
                    <Grid item xs={12} md={4}>
                      <Selector
                        label="Video"
                        video={values.main?.video}
                        name="selectedVideo"
                        setOpenModal={() => setOpenSelector("selectedVideo")}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    {selectedVideoLabel &&
                      selectedVideoLabel !== "0" &&
                      !updatedVideoName && <Div>{selectedVideoLabel}</Div>}
                    {updatedVideoName && <Div>{updatedVideoName}</Div>}
                  </Div>
                </>
              }
            />
            <Modal
              open={openSelector !== undefined}
              onClose={() => setOpenSelector()}
            >
              <Div sx={modalStyle}>
                <SingleVideo
                  layout={"row-reverse"}
                  selectorMode
                  onCancel={() => setOpenSelector()}
                  onSelect={(video) => {
                    const videoUrl = `${PROD_URL}/uploads/files/${customerId}/Videos/${video.filename}`;
                    const videoLabel = video.label;
                    handleVideoSelect(
                      openSelector,
                      videoUrl,
                      videoLabel,
                      setFieldValue
                    );
                  }}
                />
              </Div>
            </Modal>
          </>
        )}
      </Formik>
    </Div>
  );
};

export default VideoPlayerModule;
