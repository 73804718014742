import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Field, useFormikContext } from "formik";
import { Label } from "app/pages/presentation/module/form/types";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ClearIcon from "@mui/icons-material/Clear";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import JumboListNoDataPlaceholder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder";
import * as Yup from "yup";
import {
  DSOK_DEVICE_SERVER_PORT_START,
  DSOK_FE_DOMAIN,
  DSOK_SUPPLIERS,
} from "app/utils/constants/settings";
import { useSnackbar } from "notistack";
import { replaceAll } from "app/utils/stringHelpers";
import { getDeviceStatus as getPartnerDeviceStatus } from "app/services/api/partner";
import { getDeviceStatus } from "app/services/api/management";
import GeneralSection from "./sections/GeneralSection";
import PosSection from "./sections/PosSection";
import EkasaSection from "./sections/EkasaSection";

const FIELD_NAME = "DEVICES_FIELD";

export const getDevicesFieldSchema = (devices) => {
  const schema = {};
  const deviceKeys = Object.keys(devices || {});
  if (!deviceKeys?.length) {
    return schema;
  }
  deviceKeys.forEach((device) => {
    schema[device] = {
      uuid: "",
      currency: "",
      testMode: "",
      maintenance: "",
      forceMaintenance: "",
      pos: {
        supplier: "",
        ip: "",
        useHTTPS: "",
        localPort: "",
        serverPort: "",
        username: "",
        password: "",
        printReceipt: "",
        poiId: "",
        serialNumber: "",
      },
      ekasa: {
        ip: "",
        useHTTPS: "",
        localPort: "",
        serverPort: "",
        headerText: "",
        footerText: "",
        dic: "",
        cashRegisterCode: "",
        customer: {
          id: "",
          type: "",
        },
        print: {
          options: {
            to: "m.valasek@intesso.sk",
          },
          printerName: "",
        },
        transactionType: "",
        eKasaType: "none",
      },
      generalServer: {
        enableGeneralServer: "",
        localPort: "",
        serverPort: "",
      },
      location: "",
      orderProcess: {
        enablePosPayment: "",
        enablePrintReceipt: "",
      },
    };
  });
  return schema;
};

export const getDevicesFieldValidationSchema = (t) => ({
  uuid: Yup.string().required(t("messages.checkRequiredFields")),
  currency: Yup.string().required(t("messages.checkRequiredFields")),
  pos: Yup.object().shape({
    supplier: Yup.string().required(t("messages.checkRequiredFields")),
    ip: Yup.string().required(t("messages.checkRequiredFields")),
    localPort: Yup.string().required(t("messages.checkRequiredFields")),
    serverPort: Yup.string().required(t("messages.checkRequiredFields")),
  }),
  ekasa: Yup.object().shape({
    headerText: Yup.string(),
    footerText: Yup.string(),
    dic: Yup.string().required(t("messages.checkRequiredFields")),
    cashRegisterCode: Yup.string().required(t("messages.checkRequiredFields")),
    customer: Yup.object().shape({
      id: Yup.string().required(t("messages.checkRequiredFields")),
      type: Yup.string().required(t("messages.checkRequiredFields")),
    }),
    print: Yup.object().shape({
      options: Yup.object().shape({
        to: Yup.string().required(t("messages.checkRequiredFields")),
      }),
      printerName: Yup.string().required(t("messages.checkRequiredFields")),
    }),
    transactionType: Yup.string().required(t("messages.checkRequiredFields")),
    eKasaType: Yup.string().required(t("messages.checkRequiredFields")),
  }),
  location: Yup.string().required(t("messages.checkRequiredFields")),
});

const DevicesField = ({
  handleChange,
  expanded,
  isAdmin,
  isPartnerAdmin,
  token,
  customerUrl,
  isPartner,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const [selectedDevice, setSelectedDevice] = useState("1");
  const [manuelChecking, setManuelChecking] = useState(false);
  const { showDialog, hideDialog } = useJumboDialog();
  const { enqueueSnackbar } = useSnackbar();

  const deviceKeys = Object.keys(values.customer.devices || {});

  const handleManualCheckDevice = async (deviceId) => {
    setManuelChecking(true);
    const handler = isPartnerAdmin ? getPartnerDeviceStatus : getDeviceStatus;
    const status = await handler(deviceId, customerUrl, token);

    const ekasaMessage = `${t("pages.dsokDetails.ekasa")}: ${
      status.ekasaStatus
        ? t("pages.dsokDetails.deviceOK")
        : t("pages.dsokDetails.deviceUnavailable")
    }`;

    const posMessage = `${t("pages.dsokDetails.pos")}: ${
      status.posStatus
        ? t("pages.dsokDetails.deviceOK")
        : t("pages.dsokDetails.deviceUnavailable")
    }`;
    enqueueSnackbar(ekasaMessage, {
      variant: status.ekasaStatus ? "success" : "error",
    });
    enqueueSnackbar(posMessage, {
      variant: status.posStatus ? "success" : "error",
    });
    setManuelChecking(false);
  };

  const handleAddDevice = () => {
    const posPort =
      DSOK_DEVICE_SERVER_PORT_START + (+deviceKeys.length - 1) * 16;
    const ekasaPort = posPort + 1;
    const generalServerPort = ekasaPort + 1;
    setFieldValue("customer.devices", {
      ...values.customer.devices,
      [deviceKeys.length + 1]: {
        location: "1",
        pos: { supplier: DSOK_SUPPLIERS[0].code, serverPort: posPort },
        ekasa: {
          print: { options: { to: "m.valasek@intesso.sk" } },
          serverPort: ekasaPort,
        },
        generalServer: { serverPort: generalServerPort },
      },
    });
  };

  const handleDeleteDevice = (device) => {
    const newDevices = {};
    let deleted = 0;
    deviceKeys.forEach((key, index) => {
      if (key === device) {
        deleted++;
        return;
      }
      newDevices[index + 1 - deleted] = values.customer.devices[key];
    });
    setFieldValue("customer.devices", newDevices);
    setSelectedDevice(deviceKeys[0]);
    hideDialog();
  };

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.dsokDetails.devices")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          spacing={1}
          px={2}
        >
          <TabContext value={selectedDevice}>
            <Stack width="100%">
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <TabList onChange={(_, val) => setSelectedDevice(val)}>
                  {deviceKeys.map((item) => {
                    let demoDeviceText = "";
                    if (values.customer.devices[item].demoMode) {
                      demoDeviceText = " - DEMO";
                    }
                    return (
                      <Tab
                        key={`tab_device_${item}`}
                        label={
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            {`${item} ` + demoDeviceText}
                            {isAdmin && (
                              <IconButton
                                onClick={() =>
                                  showDialog({
                                    variant: "confirm",
                                    title: t(
                                      "widgets.confirmDialog.areYouSure"
                                    ),
                                    onYes: () => handleDeleteDevice(item),
                                    onNo: () => hideDialog(),
                                  })
                                }
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Stack>
                        }
                        value={item}
                      />
                    );
                  })}
                </TabList>
                {isAdmin && (
                  <IconButton onClick={handleAddDevice}>
                    <AddCircleIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
              {!deviceKeys?.length ? (
                <JumboListNoDataPlaceholder />
              ) : (
                deviceKeys.map((device) => {
                  let isFrontendDemoDevice = false;
                  if (values.customer.devices[device].demoMode) {
                    isFrontendDemoDevice = true;
                  }
                  const customerId = values.customer.customerId;
                  const customerCode = values.customer.code;
                  const locationId = values.customer.devices[device].location;
                  const locationCode =
                    values.customer.locations[locationId].code;
                  const deviceUUID = values.customer.devices[device].uuid;
                  const env =
                    values.customer.beDomain ===
                    "https://staging.gastrokiosk.online"
                      ? "stage"
                      : "prod";
                  //compose preview link based on selected frontend domain
                  let previewButtonLink = `https://${replaceAll(
                    values.customer.code,
                    "-",
                    ""
                  )}.${DSOK_FE_DOMAIN}/${
                    values.customer?.defaultLanguage
                  }/?kioskId=${values.customer.devices[device]?.uuid}`;
                  if (values.customer?.defaultLanguage === "sk") {
                    previewButtonLink = `https://${replaceAll(
                      values.customer.code,
                      "-",
                      ""
                    )}.${DSOK_FE_DOMAIN}/?kioskId=${
                      values.customer.devices[device]?.uuid
                    }`;
                  }

                  let callScreenUrl = null;
                  switch (values?.customer?.frontendType) {
                    case "easyPay":
                      previewButtonLink = `https://easypay.gastrokiosk.online/?customerId=${customerId}&deviceId=${deviceUUID}`;
                      callScreenUrl = `${window.location.origin}/easy-pay/call-screen/${customerCode}/${locationCode}/${env}/sk-SK/15000`;
                      break;
                    case "smartKiosk":
                      previewButtonLink = `https://dsok.smartkiosk.sk/?customerId=${customerId}&deviceId=${deviceUUID}`;
                      break;
                    default:
                      break;
                  }
                  const posSupplier =
                    values.customer.devices[device].pos?.supplier;
                  return (
                    <TabPanel
                      key={`tab_panel_device_${device}`}
                      value={device}
                      sx={{
                        pb: 1,
                        pr: 0,
                        pl: 0,
                      }}
                    >
                      <Grid container spacing={1}>
                        <GeneralSection
                          device={device}
                          setFieldValue={setFieldValue}
                          values={values}
                          t={t}
                          isAdmin={isAdmin}
                          isPartnerAdmin={isPartnerAdmin}
                          isPartner={isPartner}
                          previewButtonLink={previewButtonLink}
                          callScreenUrl={callScreenUrl}
                          isFrontendDemoDevice={isFrontendDemoDevice}
                          manuelChecking={manuelChecking}
                          handleManualCheckDevice={handleManualCheckDevice}
                        />
                        {isAdmin && (
                          <>
                            <PosSection
                              device={device}
                              setFieldValue={setFieldValue}
                              values={values}
                              t={t}
                              posSupplier={posSupplier}
                            />
                            <EkasaSection
                              device={device}
                              setFieldValue={setFieldValue}
                              values={values}
                              t={t}
                              posSupplier={posSupplier}
                            />
                          </>
                        )}
                        {isAdmin && (
                          <>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                {t("pages.dsokDetails.generalServer")}
                              </Typography>
                              <Divider />
                            </Grid>
                            {isAdmin && (
                              <Grid item xs={12} md={6} lg={4}>
                                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        name={`customer.devices.${device}.generalServer.enableGeneralServer`}
                                        component={Switch}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `customer.devices.${device}.generalServer.enableGeneralServer`,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      <Label
                                        text={t(
                                          "pages.dsokDetails.enableGeneralServer"
                                        )}
                                        color="primary"
                                        helpText={t(
                                          "pages.dsokDetails.enableGeneralServerHelp"
                                        )}
                                      />
                                    }
                                    checked={
                                      values.customer.devices[device]
                                        .generalServer?.enableGeneralServer
                                    }
                                  />
                                </Div>
                              </Grid>
                            )}
                            <Grid item xs={12} md={3} lg={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  name={`customer.devices.${device}.generalServer.localPort`}
                                  label={t("pages.dsokDetails.localPort")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={3} lg={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  name={`customer.devices.${device}.generalServer.serverPort`}
                                  label={t("pages.dsokDetails.serverPort")}
                                />
                              </Div>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </TabPanel>
                  );
                })
              )}
            </Stack>
          </TabContext>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default DevicesField;
