import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useJumboTheme } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  Snackbar,
  Typography,
} from "@mui/material";
import { updateModule } from "app/services/api/module";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import { useFormikContext } from "formik";
import FormGenerator from "./form/FormGenerator";
import { getBlock } from "app/services/api/block";
import { usePrompt } from "app/shared/Prompt/Prompt";

const EditModule = ({
  content,
  settings,
  type,
  presentation,
  module,
  title,
  updateValue,
  loadModule,
}) => {
  const { url, blockUrl } = useParams();
  const { t } = useTranslation();
  const { theme } = useJumboTheme();
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState();
  const [block, setBlock] = useState();
  const [unsaved, setUnsaved] = useState(false);
  const [contentSettings, setContentSettings] = useState();
  const moduleTitle = t(`pages.modules.types.${type}`);
  const [formGeneratorID, setFormGeneratorID] = useState(Date.now());
  const template = presentation?.sourceId;

  usePrompt(t("messages.areYouSureToLeave"), unsaved);

  const { enqueueSnackbar } = useSnackbar();

  const FormikController = ({ setErrors }) => {
    const { values, errors } = useFormikContext();
    useEffect(() => {
      if (values) {
        setContentSettings(values);
      }
    }, [values]);

    useEffect(() => {
      setErrors(errors);
    }, [errors]);

    return null;
  };
  useEffect(() => {
    getBlock(blockUrl).then((block) => setBlock(block));
  }, [blockUrl]);

  useEffect(() => {
    if (
      contentSettings &&
      updateValue?.settings?.parameters &&
      module?.parameters
    ) {
      const changedSettingFields = {};
      Object.keys(updateValue.settings.parameters).forEach((key) => {
        const settingField = updateValue.settings.parameters[key];
        if (
          settingField !== module.parameters[key] &&
          settingField !== undefined
        ) {
          changedSettingFields[key] = settingField;
        }
      });
      const changedContentFields = {};
      Object.keys(contentSettings).forEach((key) => {
        const settingField = contentSettings[key];
        if (
          settingField !== module.parameters[key] &&
          settingField !== undefined
        ) {
          changedContentFields[key] = settingField;
        }
      });
      const moduleVal = {
        settings: {
          ...updateValue.settings,
          parameters: {
            ...module.parameters,
            ...changedSettingFields,
            ...changedContentFields,
          },
        },
      };
      moduleVal.settings.parameters = Object.keys(moduleVal.settings.parameters)
        .sort()
        .reduce((obj, key) => {
          obj[key] = moduleVal.settings.parameters[key];
          return obj;
        }, {});
      const defaultParameters = structuredClone(module.parameters);
      const orderedDefaultParameters = Object.keys(defaultParameters)
        .sort()
        .reduce((obj, key) => {
          obj[key] = defaultParameters[key];
          return obj;
        }, {});
      setUnsaved(
        JSON.stringify(moduleVal.settings.parameters) !==
          JSON.stringify(orderedDefaultParameters)
      );
    }
  }, [contentSettings, updateValue, module]);

  const handleUpdateModule = async () => {
    setUpdating(true);
    const changedSettingFields = {};
    Object.keys(updateValue.settings.parameters).forEach((key) => {
      const settingField = updateValue.settings.parameters[key];
      if (settingField !== module.parameters[key]) {
        changedSettingFields[key] = settingField;
      }
    });
    const changedContentFields = {};
    contentSettings &&
      Object.keys(contentSettings).forEach((key) => {
        const settingField = contentSettings[key];
        if (settingField !== module.parameters[key]) {
          changedContentFields[key] = settingField;
        }
      });
    try {
      const moduleVal = {
        settings: {
          ...updateValue.settings,
          parameters: {
            ...module.parameters,
            ...changedSettingFields,
            ...changedContentFields,
          },
        },
      };
      Object.keys(
        moduleVal.settings.parameters.parameters_settings?.fields || {}
      ).forEach((field) => {
        if (
          moduleVal.settings.parameters.parameters_settings?.fields[field]
            .type === "boolean"
        ) {
          if (moduleVal.settings.parameters[field] === undefined) {
            moduleVal.settings.parameters[field] = false;
          }
        }
      });
      const formErrors = { ...errors, ...updateValue.errors };
      if (formErrors && Object.keys(formErrors).length) {
        const message = `${t("messages.checkRequiredFields")}`;
        enqueueSnackbar(message, {
          variant: "error",
        });
      } else {
        await updateModule(moduleVal);
        await loadModule();
        setFormGeneratorID(Date.now());
        const message = `${t("buttons.update")} ${t(
          "notifications.wasSuccessful"
        )}`;
        enqueueSnackbar(message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = `${t("buttons.update")} ${t("notifications.wasFailed")}`;
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
    setUpdating(false);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to="/">{t("sidebar.menuItem.home")}</Link>
        <Link to="/presentations">{t("sidebar.menuItem.presentations")}</Link>
        <Link to={`/presentation/${url}`}>{presentation.name}</Link>
        {!template && (
          <Link to={`/presentation/${url}/block/${blockUrl}`}>
            {block?.parameters?.name || ""}
          </Link>
        )}
        <Typography color="text.primary">{moduleTitle}</Typography>
      </Breadcrumbs>
      <Div
        sx={{
          display: "flex",
        }}
      >
        <JumboCardQuick
          title={
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                minHeight: 29,
                [theme.breakpoints.down("md")]: {
                  flexWrap: "wrap",
                },
              }}
            >
              <Typography
                variant={"h4"}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down("md")]: {
                    minWidth: "100%",
                    marginBottom: 2,
                  },
                }}
              >
                {moduleTitle}
              </Typography>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: "divider",
            "& .MuiCardHeader-action": {
              my: -0.75,
            },
          }}
          sx={{
            flex: 1,
            mr: 2,
          }}
          wrapperSx={{
            p: 1,
            "&:last-child": {
              pb: 2,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        >
          <FormGenerator
            key={formGeneratorID}
            parameters={module.parameters}
            type="content"
          >
            <FormikController setErrors={setErrors} />
          </FormGenerator>
          {content}
        </JumboCardQuick>
        <JumboCardQuick
          title={
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("md")]: {
                  flexWrap: "wrap",
                },
              }}
            >
              <Typography
                variant={"h4"}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down("md")]: {
                    minWidth: "100%",
                    marginBottom: 2,
                  },
                }}
              >
                <Div sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Link
                    to={
                      template
                        ? `/presentation/${url}`
                        : `/presentation/${url}/block/${blockUrl}`
                    }
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<FastRewindIcon />}
                      sx={{
                        height: "100%",
                      }}
                    >
                      {t("buttons.back")}
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    startIcon={<BrowserUpdatedIcon />}
                    sx={{
                      boxShadow: "none",
                      backgroundColor: unsaved && "#F39711",
                    }}
                    size="small"
                    disabled={updating}
                    onClick={handleUpdateModule}
                  >
                    {t("buttons.update")}
                  </Button>
                </Div>
              </Typography>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: "divider",
            "& .MuiCardHeader-action": {
              my: -0.75,
            },
          }}
          sx={{
            maxWidth: 300,
            flex: 1,
          }}
          wrapperSx={{
            p: 0,
            "&:last-child": {
              pb: 2,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        >
          {settings}
        </JumboCardQuick>
      </Div>
      <Snackbar
        open={unsaved}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="info">
          <AlertTitle>{t("messages.unsavedChanges.title")}</AlertTitle>
          {t("messages.unsavedChanges.description")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditModule;
