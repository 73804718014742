import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Modal,
  Stack,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Field, useFormikContext } from "formik";
import {
  Label,
  UploadComponent,
} from "app/pages/presentation/module/form/types";
import * as Yup from "yup";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Galleries from "app/pages/media/galleries/Galleries";
import { getGallery, uploadToImagekit } from "app/services/api/media";
import DeleteIcon from "@mui/icons-material/Delete";
import mapValues from "lodash/mapValues";

const FIELD_NAME = "SETTINGS_FIELD";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  maxHeight: "90vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const SETTINGS_FIELD_SCHEMA = {
  inactivityTimeout: "",
  orderSuccesPageTimeout: "",
  orderErrorPageTimeout: "",
  accessibilityButton: "",
  product: {
    displayOutOfStockProducts: "",
    displayAllergens: "",
    columns: "",
    ProductConfigModal: {
      columns: "",
    },
  },
  mainScreen: {
    slider: {
      duration: "",
      galleryUrl: {},
    },
    title: {
      display: "",
      label: {},
    },
  },
  takeAway: "",
  locator: "",
  paymentButton: "",
};

export const getSettingsFieldValidationSchema = (t) => ({
  inactivityTimeout: Yup.number().required(t("messages.checkRequiredFields")),
  orderSuccesPageTimeout: Yup.number().required(
    t("messages.checkRequiredFields")
  ),
  orderErrorPageTimeout: Yup.number().required(
    t("messages.checkRequiredFields")
  ),
  product: Yup.object().shape({
    columns: Yup.number().required(t("messages.checkRequiredFields")),
    ProductConfigModal: Yup.object().shape({
      columns: Yup.number().required(t("messages.checkRequiredFields")),
    }),
  }),
  mainScreen: Yup.object().shape({
    slider: Yup.object().shape({
      duration: Yup.number().required(t("messages.checkRequiredFields")),
      galleryUrl: Yup.lazy((lazyObj) =>
        Yup.object(
          mapValues(lazyObj, () =>
            Yup.string().required(t("messages.checkRequiredFields"))
          )
        )
      ),
    }),
    title: Yup.object().shape({
      label: Yup.lazy((lazyObj) =>
        Yup.object(
          mapValues(lazyObj, () =>
            Yup.string().required(t("messages.checkRequiredFields"))
          )
        )
      ),
    }),
  }),
});

const SettingsField = ({ handleChange, expanded, isAdmin, isPartner }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [openSelector, setOpenSelector] = useState();
  const [galleryTitle, setGalleryTitle] = useState({});
  const [galleryImage, setGalleryImage] = useState({});

  useEffect(() => {
    values.customer.languages &&
      setSelectedLanguage(values.customer.languages[0]);
  }, [values.customer.languages]);

  const updateGalleries = async () => {
    const newGalleryImage = {};
    const newGalleryTitle = {};
    for (const gallery of Object.keys(
      values?.settings.mainScreen?.slider?.galleryUrl || {}
    )) {
      await getGallery(
        values?.settings.mainScreen?.slider?.galleryUrl[gallery]
      ).then((response) => {
        newGalleryTitle[gallery] = response?.label || "";
        const imageUrl = (response?.images && response?.images[0]?.path) || "";
        newGalleryImage[gallery] = imageUrl;
      });
    }
    setGalleryImage({ ...galleryImage, ...newGalleryImage });
    setGalleryTitle({
      ...galleryTitle,
      ...newGalleryTitle,
    });
  };

  useEffect(() => {
    if (values?.settings.mainScreen?.slider?.galleryUrl) {
      updateGalleries();
    } else {
      setGalleryImage({});
    }
  }, [values.settings.mainScreen]);

  const handleGallerySelect = (galleryItem, language) => {
    setOpenSelector();
    setFieldValue(
      `settings.mainScreen.slider.galleryUrl.${language}`,
      galleryItem.url
    );
  };

  const handleImageUpload = async (image) => {
    return await uploadToImagekit(image, "dsok/logo");
  };
  const handleTableImageUpload = async (image) => {
    return await uploadToImagekit(image, "dsok/tableImage");
  };

  const Selector = ({ label, help, title, image, setOpenModal, name }) => {
    return (
      <Div sx={{ mt: 0, mb: 2.5 }}>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            margin: "0 0 0 10px",
            background: "white",
            color: "text.secondary",
            width: "fit-content",
          }}
        >
          {label}
        </Typography>
        <ImageListItem
          sx={{
            "& .MuiImageListItemBar-root": {
              transition: "visibility 0s, opacity 0.3s linear",
              opacity: 0,
            },

            "&:hover .MuiImageListItemBar-root": {
              visibility: "visible",
              opacity: 1,
            },
            width: "fit-content",
            height: "auto",
          }}
        >
          <Avatar
            src={image || "/images/pages/not_found.svg"}
            variant={"rounded"}
            sx={{
              height: "auto",
              width: `100%`,
              maxWidth: "250px",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal(true)}
          />
          {image && (
            <ImageListItemBar
              subtitle={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    mb: 0,
                  }}
                >
                  <Typography variant="body">{title}</Typography>
                  <DeleteIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setFieldValue(name, "")}
                  />
                </Stack>
              }
            />
          )}
        </ImageListItem>
      </Div>
    );
  };

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.presentation.settings")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                type="number"
                InputLabelProps={{ required: false }}
                required
                fullWidth
                name="settings.inactivityTimeout"
                label={
                  <Label
                    text={t("pages.dsokDetails.inactivityTimeout")}
                    helpText={t("pages.dsokDetails.inactivityTimeoutHelp")}
                    required
                  />
                }
                InputProps={{
                  value: values.settings?.inactivityTimeout,
                  onChange: (e) =>
                    setFieldValue("settings.inactivityTimeout", e.target.value),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                type="number"
                InputLabelProps={{ required: false }}
                required
                fullWidth
                name="settings.orderSuccesPageTimeout"
                label={
                  <Label
                    text={t("pages.dsokDetails.orderSuccessTimeout")}
                    helpText={t("pages.dsokDetails.orderSuccessTimeoutHelp")}
                    required
                  />
                }
                InputProps={{
                  value: values.settings?.orderSuccesPageTimeout,
                  onChange: (e) =>
                    setFieldValue(
                      "settings.orderSuccesPageTimeout",
                      e.target.value
                    ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                type="number"
                InputLabelProps={{ required: false }}
                required
                fullWidth
                name="settings.orderErrorPageTimeout"
                label={
                  <Label
                    text={t("pages.dsokDetails.orderErrorTimeout")}
                    helpText={t("pages.dsokDetails.orderErrorTimeoutHelp")}
                    required
                  />
                }
                InputProps={{
                  value: values.settings?.orderErrorPageTimeout,
                  onChange: (e) =>
                    setFieldValue(
                      "settings.orderErrorPageTimeout",
                      e.target.value
                    ),
                }}
              />
            </FormControl>
          </Grid>
          {isAdmin && (
            <>
              <Grid item xs={12} md={12} lg={12}></Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="settings.accessibilityButton"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue(
                            "settings.accessibilityButton",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={t("pages.dsokDetails.accessibilityButton")}
                    checked={values.settings?.accessibilityButton}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="settings.takeAway"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue("settings.takeAway", e.target.checked)
                        }
                      />
                    }
                    label={t("pages.dsokDetails.takeAway")}
                    checked={values.settings?.takeAway}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="settings.locator"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue("settings.locator", e.target.checked)
                        }
                      />
                    }
                    label={t("pages.dsokDetails.locator")}
                    checked={values.settings?.locator}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="settings.paymentButton"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue(
                            "settings.paymentButton",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={t("pages.dsokDetails.paymentButton")}
                    checked={values.settings?.paymentButton}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="settings.disableCategories"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue(
                            "settings.disableCategories",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={t("pages.dsokDetails.disableCategories")}
                    checked={values.settings?.disableCategories}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}></Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Label text={t("pages.dsokDetails.mainLogo")} />
                <UploadComponent
                  name="customer.mainLogo"
                  setFieldValue={setFieldValue}
                  onUpload={handleImageUpload}
                  value={values.customer.mainLogo}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Label text={t("pages.dsokDetails.tableImage")} />
                <UploadComponent
                  name="customer.tableImage"
                  setFieldValue={setFieldValue}
                  onUpload={handleTableImageUpload}
                  value={values.customer.tableImage}
                />
              </Grid>
            </>
          )}
          {isAdmin && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <Label
                    text={t("pages.dsokDetails.product")}
                    helpText={t("pages.dsokDetails.productHelp")}
                    required={false}
                    sx={{ color: "text.primary" }}
                  />
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="settings.product.displayOutOfStockProducts"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue(
                            "settings.product.displayOutOfStockProducts",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={
                      <Label
                        text={t("pages.dsokDetails.displayOutOfStock")}
                        helpText={t("pages.dsokDetails.displayOutOfStockHelp")}
                        sx={{ color: "text.primary" }}
                      />
                    }
                    checked={
                      values.settings?.product?.displayOutOfStockProducts
                    }
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="settings.product.displayAllergens"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue(
                            "settings.product.displayAllergens",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={
                      <Label
                        text={t("pages.dsokDetails.displayAllergens")}
                        helpText={t("pages.dsokDetails.displayAllergensHelp")}
                        sx={{ color: "text.primary" }}
                      />
                    }
                    checked={values.settings?.product?.displayAllergens}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                  <JumboTextField
                    type="number"
                    InputLabelProps={{ required: false }}
                    required
                    fullWidth
                    name="settings.product.columns"
                    label={
                      <Label
                        text={t("pages.dsokDetails.productColumns")}
                        required
                      />
                    }
                    InputProps={{
                      value: values.settings?.product?.columns,
                      onChange: (e) =>
                        setFieldValue(
                          "settings.product.columns",
                          e.target.value
                        ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                  <JumboTextField
                    type="number"
                    InputLabelProps={{ required: false }}
                    required
                    fullWidth
                    name="settings.product.ProductConfigModal.columns"
                    label={
                      <Label
                        text={t("pages.dsokDetails.productModalColumns")}
                        required
                      />
                    }
                    InputProps={{
                      value:
                        values.settings?.product?.ProductConfigModal?.columns,
                      onChange: (e) =>
                        setFieldValue(
                          "settings.product.ProductConfigModal.columns",
                          e.target.value
                        ),
                    }}
                  />
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              <Label
                text={t("pages.dsokDetails.mainScreen")}
                helpText={t("pages.dsokDetails.mainScreenHelp")}
                required={false}
                sx={{ color: "text.primary" }}
              />
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
              <FormControlLabel
                control={
                  <Field
                    name="settings.mainScreen.title.display"
                    component={Switch}
                    onChange={(e) =>
                      setFieldValue(
                        "settings.mainScreen.title.display",
                        e.target.checked
                      )
                    }
                  />
                }
                label={
                  <Label
                    text={t("pages.dsokDetails.displayTitle")}
                    helpText={t("pages.dsokDetails.displayTitleHelp")}
                    sx={{ color: "text.primary" }}
                  />
                }
                checked={values.settings?.mainScreen?.title?.display}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4} lg={1}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                type="number"
                InputLabelProps={{ required: false }}
                required
                fullWidth
                name="settings.mainScreen.slider.duration"
                label={
                  <Label
                    text={t("pages.dsokDetails.slideChangeIn")}
                    helpText={t("pages.dsokDetails.slideChangeInHelp")}
                    required
                  />
                }
                InputProps={{
                  value: values.settings?.mainScreen?.slider?.duration / 1000,
                  onChange: (e) =>
                    setFieldValue(
                      "settings.mainScreen.slider.duration",
                      e.target.value * 1000
                    ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TabContext value={selectedLanguage}>
              <Stack sx={{ mb: 2 }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <TabList onChange={(_, val) => setSelectedLanguage(val)}>
                    {values.customer.languages?.map((lang) => {
                      return (
                        <Tab
                          key={lang}
                          label={t(`languages.${lang}`)}
                          value={lang}
                        />
                      );
                    })}
                  </TabList>
                </Box>
                {values.customer.languages?.map((lang) => {
                  return (
                    <TabPanel
                      key={`panel_${lang}`}
                      value={lang}
                      sx={{ pb: 1, pr: 0, minHeight: 350, pl: 1 }}
                    >
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          InputLabelProps={{ required: false }}
                          name={`settings.mainScreen.title.label.${lang}`}
                          label={
                            <Label
                              text={t("pages.dsokDetails.title")}
                              helpText={t("pages.dsokDetails.titleHelp")}
                              required
                            />
                          }
                        />
                      </Div>
                      <Selector
                        label={
                          <Label
                            text={t("pages.dsokDetails.sliderGallery")}
                            helpText={t("pages.dsokDetails.sliderGalleryHelp")}
                            required
                          />
                        }
                        image={galleryImage[lang]}
                        title={galleryTitle[lang]}
                        name={`settings.mainScreen.slider.galleryUrl.${lang}`}
                        setOpenModal={() => setOpenSelector(lang)}
                      />
                    </TabPanel>
                  );
                })}
              </Stack>
            </TabContext>
          </Grid>
        </Grid>
      </AccordionDetails>
      <Modal
        open={openSelector !== undefined}
        onClose={() => setOpenSelector()}
      >
        <Div sx={modalStyle}>
          <Galleries
            selectorMode
            handleSelect={(image) => handleGallerySelect(image, openSelector)}
          />
        </Div>
      </Modal>
    </Accordion>
  );
};

export default SettingsField;
