import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useJumboTheme } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import {
  Button,
  Typography,
  Breadcrumbs,
  Snackbar,
  Alert,
  AlertTitle,
  TextField,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import merge from "lodash/merge";
import mapValues from "lodash/mapValues";

import { Form, Formik } from "formik";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import {
  editDSOKDetails,
  generateToken,
  getCustomer,
} from "app/services/api/management";

import LocationsField, {
  getLocationsFieldSchema,
  getLocationsFieldValidationSchema,
} from "./fields/LocationsField";
import * as Yup from "yup";
import DevicesField, {
  getDevicesFieldSchema,
  getDevicesFieldValidationSchema,
} from "./fields/DevicesField";
import SettingsField, {
  SETTINGS_FIELD_SCHEMA,
  getSettingsFieldValidationSchema,
} from "./fields/SettingsField";
import MaintenanceField, {
  getMaintenanceFieldSchema,
  getMaintenanceFieldValidationSchema,
} from "./fields/MaintenanceField";
import OrderManagementField, {
  ORDER_MANAGEMENT_FIELD_SCHEMA,
  getOrderManagementFieldValidationSchema,
} from "./fields/OrderManagementField";
import { mergeDeep } from "app/utils/objectHelpers";
import UISettingsField, {
  UI_SETTINGS_FIELD_SCHEMA,
  getUISettingsFieldValidationSchema,
} from "./fields/UISettingsField";
import TokenIcon from "@mui/icons-material/Token";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DSOK_DEVICE_SERVER_PORT_START } from "app/utils/constants/settings";

import PrinterSettingsFields, {
  getPrinterSettingsFieldSchema,
  getPrinterSettingsFieldValidationSchema,
} from "./fields/PrinterSettingsFields";
import DsokCustomerField, {
  DSOK_CUSTOMER_FIELD_SCHEMA,
  getDsokCustomerFieldValidationSchema,
} from "./fields/DsokCustomerField";

const DSOKDetails = () => {
  const { url } = useParams();
  const { t } = useTranslation();
  const { theme } = useJumboTheme();
  const { getAuthUser } = useJumboAuth();
  const { customer: authUser } = getAuthUser() || {};
  const [customer, setCustomer] = useState();
  const [unsaved, setUnsaved] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const customerUrl = url || authUser?.url || "";
  const isAdmin = authUser?.customerType === 1;
  const isPartner = authUser?.customerType === 2;
  const { showDialog, hideDialog } = useJumboDialog();

  const validationSchema = Yup.object().shape({
    customer: Yup.object().shape({
      ...getDsokCustomerFieldValidationSchema(t),
      locations: Yup.lazy((lazyObj) =>
        Yup.object(
          mapValues(lazyObj, () =>
            Yup.object(getLocationsFieldValidationSchema(t))
          )
        )
      ),
      devices: Yup.lazy((lazyObj) =>
        Yup.object(
          mapValues(lazyObj, () =>
            Yup.object(getDevicesFieldValidationSchema(t))
          )
        )
      ),
    }),
    settings: Yup.object().shape(getSettingsFieldValidationSchema(t)),
    maintenance: Yup.object().shape(getMaintenanceFieldValidationSchema(t)),
    printerSettings: Yup.object().shape(
      getPrinterSettingsFieldValidationSchema(t)
    ),
    orderManagement: Yup.object().shape(
      getOrderManagementFieldValidationSchema(t)
    ),
    uiSettings: Yup.object().shape(getUISettingsFieldValidationSchema(t)),
  });

  const loadCustomer = async () => {
    await getCustomer(customerUrl).then((customer) => setCustomer(customer));
  };

  const handleUpdateCustomer = async (settings) => {
    Object.keys(settings?.customer.locations || {}).forEach(
      (key) => (settings.customer.locations[key].id = +key)
    );
    Object.keys(settings?.customer?.devices || {}).forEach((key) => {
      settings.customer.devices[key].id = +key;
      const posPort = DSOK_DEVICE_SERVER_PORT_START + (+key - 1) * 16;
      const ekasaPort = posPort + 1;
      const generalServerPort = ekasaPort + 1;
      // console.log('device', key, posPort, ekasaPort, generalServerPort)
      settings.customer.devices[key].pos.serverPort = posPort;
      settings.customer.devices[key].ekasa.serverPort = ekasaPort;
      settings.customer.devices[key].generalServer.serverPort =
        generalServerPort;
    });
    const dsok = { ...customer.dsokDetails, ...settings };
    setUpdating(true);
    try {
      await editDSOKDetails({ customerUrl, ...dsok });
      await loadCustomer();
      const message = `${t("buttons.update")} ${t(
        "notifications.wasSuccessful"
      )}`;
      enqueueSnackbar(message, {
        variant: "success",
      });
    } catch (error) {
      const message = `${t("buttons.update")} ${t("notifications.wasFailed")}`;
      enqueueSnackbar(message, {
        variant: "error",
      });
      setUpdating(false);
    }
    setUpdating(false);
  };

  const handleGenerateToken = async () => {
    setUpdating(true);
    try {
      const token = await generateToken(customer?.id);
      showDialog({
        variant: "default",
        title: t("pages.dsokDetails.generateToken"),
        content: (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="flex-end"
          >
            <TextField
              fullWidth
              name="token"
              value={token}
              multiline
              rows={3}
              margin="dense"
              sx={{ mt: 0 }}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none",
                height: "38px",
                width: "fit-content",
              }}
              startIcon={<ContentCopyIcon />}
              onClick={() => {
                navigator.clipboard.writeText(token);
                const message = `${t("buttons.copy")} ${t(
                  "notifications.wasSuccessful"
                )}`;
                enqueueSnackbar(message, {
                  variant: "success",
                });
                hideDialog();
              }}
            >
              {t("buttons.copy")}
            </Button>
          </Box>
        ),
      });
    } catch (error) {
      const message = `${t("pages.dsokDetails.generateToken")} ${t(
        "notifications.wasFailed"
      )}`;
      enqueueSnackbar(message, {
        variant: "error",
      });
      setUpdating(false);
    }
    setUpdating(false);
  };

  const getInitialValues = () => ({
    customer: {
      ...mergeDeep(DSOK_CUSTOMER_FIELD_SCHEMA, customer.dsokSettings?.customer),
      locations: merge(
        getLocationsFieldSchema(customer.dsokSettings?.customer?.locations),
        customer?.dsokSettings?.customer?.locations || {}
      ),
      devices: merge(
        getDevicesFieldSchema(customer.dsokSettings?.customer?.devices),
        customer?.dsokSettings?.customer?.devices || {}
      ),
    },
    settings: merge(SETTINGS_FIELD_SCHEMA, customer.dsokSettings?.settings),
    maintenance: merge(
      getMaintenanceFieldSchema(
        Object.keys(customer.dsokSettings?.maintenance?.message || {})
      ),
      customer.dsokSettings?.maintenance
    ),
    printerSettings: merge(
      getPrinterSettingsFieldSchema(
        Object.keys(customer.dsokSettings?.printerSettings?.message || {})
      ),
      customer.dsokSettings?.printerSettings
    ),
    orderManagement: merge(
      ORDER_MANAGEMENT_FIELD_SCHEMA,
      customer.dsokSettings?.orderManagement
    ),
    uiSettings: merge(
      UI_SETTINGS_FIELD_SCHEMA,
      customer.dsokSettings?.uiSettings
    ),
  });

  useEffect(() => {
    loadCustomer();
  }, []);

  const initialValues = customer && getInitialValues();
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to="/">{t("sidebar.menuItem.home")}</Link>
        {isAdmin && (
          <Link to="/customers">{t("sidebar.menuItem.customers")}</Link>
        )}
        <Typography color="text.primary">
          {`${customer?.companyName || ""}`}
        </Typography>
      </Breadcrumbs>
      <Div
        sx={{
          display: "flex",
        }}
      >
        <JumboCardQuick
          title={
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: 29,
                [theme.breakpoints.down("md")]: {
                  flexWrap: "wrap",
                },
              }}
            >
              <Typography
                variant={"h4"}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down("md")]: {
                    minWidth: "100%",
                    marginBottom: 2,
                  },
                }}
              >
                {`${customer?.companyName || ""}`}
              </Typography>
              <Div sx={{ display: "flex", gap: 2 }}>
                {isAdmin && (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      boxShadow: "none",
                      height: "38px",
                    }}
                    startIcon={<TokenIcon />}
                    disabled={updating}
                    onClick={handleGenerateToken}
                  >
                    {t("pages.dsokDetails.generateToken")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    boxShadow: "none",
                    height: "38px",
                  }}
                  startIcon={<BrowserUpdatedIcon />}
                  disabled={updating}
                  onClick={() => {
                    if (formRef.current) {
                      if (!formRef.current.isValid) {
                        const message = `${t("messages.checkRequiredFields")}`;
                        enqueueSnackbar(message, {
                          variant: "error",
                        });
                        return;
                      }
                      formRef.current.handleSubmit();
                    }
                  }}
                >
                  {t("buttons.update")}
                </Button>
              </Div>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: "divider",
            "& .MuiCardHeader-action": {
              my: -0.75,
            },
          }}
          sx={{
            flex: 1,
            mr: 2,
          }}
          wrapperSx={{
            p: 1,
            "&:last-child": {
              pb: 2,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        >
          {customer && (
            <Formik
              innerRef={formRef}
              validateOnChange={true}
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                await handleUpdateCustomer(data);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form style={{ textAlign: "left" }} noValidate>
                  {isAdmin && (
                    <DsokCustomerField
                      handleChange={handleChange}
                      expanded={expanded}
                    />
                  )}
                  {(isAdmin || isPartner) && (
                    <>
                      <LocationsField
                        handleChange={handleChange}
                        expanded={expanded}
                        isAdmin={isAdmin}
                        isPartner={isPartner}
                        customer={customer}
                      />
                    </>
                  )}
                  <DevicesField
                    handleChange={handleChange}
                    expanded={expanded}
                    isAdmin={isAdmin}
                    customerUrl={customerUrl}
                    isPartner={isPartner}
                  />
                  <SettingsField
                    handleChange={handleChange}
                    expanded={expanded}
                    isAdmin={isAdmin}
                    isPartner={isPartner}
                  />
                  <MaintenanceField
                    handleChange={handleChange}
                    expanded={expanded}
                  />
                  <PrinterSettingsFields
                    handleChange={handleChange}
                    expanded={expanded}
                  />

                  {isAdmin && (
                    <>
                      <OrderManagementField
                        handleChange={handleChange}
                        expanded={expanded}
                      />
                    </>
                  )}
                  {(isAdmin || isPartner) && (
                    <UISettingsField
                      handleChange={handleChange}
                      expanded={expanded}
                    />
                  )}
                </Form>
              )}
            </Formik>
          )}
        </JumboCardQuick>
      </Div>
      <Snackbar
        open={unsaved}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="info">
          <AlertTitle>{t("messages.unsavedChanges.title")}</AlertTitle>
          {t("messages.unsavedChanges.description")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DSOKDetails;
