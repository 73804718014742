import { CircularProgress } from "@mui/material";
import { getModule } from "app/services/api/module";
import { getPresentation } from "app/services/api/presentation";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ArticlesModule from "./modules/ArticlesModule";
import ImagesModule from "./modules/ImagesModule";
import InfoTextLineModule from "./modules/InfoTextLineModule";
import BaseModule from "./modules/BaseModule";
import ClockModule from "./modules/ClockModule";
import WeatherModule from "./modules/WeatherModule";
import DateModule from "./modules/DateModule";
import SchoolLessonsModule from "./modules/SchoolLessonsModule";
import TextArrayModule from "./modules/TextArrayModule";
import LocalVideoPlayerModule from "./modules/LocalVideoPlayerModule";
import VideoPlayerModule from "./modules/VideoPlayerModule";
import { getVideos } from "app/services/api/media";

const MainModule = () => {
  const { url, moduleUrl } = useParams();
  const [module, setModule] = useState({});
  const [presentation, setPresentation] = useState({});
  const [videos, setVideos] = useState([]);

  const loadModule = async () => {
    const fetchedModule = await getModule(moduleUrl);
    setModule(fetchedModule);
    if (fetchedModule?.setting?.moduleCode === "video-player") {
      const user = JSON.parse(localStorage.getItem("user"));
      const videos = await getVideos(user?.customerId);
      setVideos(videos);
    }
    return fetchedModule;
  };
  const loadPresentation = async () => {
    await getPresentation(url).then((response) => setPresentation(response));
  };

  const MODULES = {
    images: (
      <ImagesModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
      />
    ),
    "info-text-line": (
      <InfoTextLineModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
      />
    ),
    "postermywall-video": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="posterMyWall"
      />
    ),
    "youtube-video": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="youtubeVideo"
      />
    ),

    weather: (
      <WeatherModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
      />
    ),
    articles: (
      <ArticlesModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
      />
    ),
    clock: (
      <ClockModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
      />
    ),
    date: (
      <DateModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
      />
    ),
    "school-lessons": (
      <SchoolLessonsModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
      />
    ),
    "school-canteen": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="schoolCanteen"
      />
    ),
    "text-array": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="textArray"
      />
    ),
    "postermywall-video": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="posterMywallVideo"
      />
    ),
    "html-code": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="htmlCode"
      />
    ),
    "rss-reader": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="rssReader"
      />
    ),
    "google-calendar": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="googleCalendar"
      />
    ),
    "restaurant-menu-main": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="restaurantMenuMain"
      />
    ),
    "restaurant-menu-list": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="restaurantMenuList"
      />
    ),
    "image-gallery": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="imageGallery"
      />
    ),
    "video-player": (
      <VideoPlayerModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="videoPlayer"
        videos={videos}
        customerId={presentation?.customerId}
      />
    ),
    "brc-video-player": (
      <LocalVideoPlayerModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="brcVideoPlayer"
      />
    ),
    "flash-messages": (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="flashMessages"
      />
    ),
    meniny: (
      <BaseModule
        module={module}
        presentation={presentation}
        loadModule={loadModule}
        type="meniny"
      />
    ),
  };

  useEffect(() => {
    loadPresentation();
    loadModule();
  }, []);

  return <>{MODULES[module?.setting?.moduleCode] || <CircularProgress />}</>;
};

export default MainModule;
